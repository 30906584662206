/* eslint-disable react-hooks/exhaustive-deps */
import {
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  sendSignInLinkToEmail,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { fetchBackendApiWrapper } from "../../utils/apiWrapper";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { openNotification } from "../../slice/notificationSlice";
import { auth } from "../../auth/firebaseConfig";
import LoadingPageSkeleton from "../../components/LoadingPageSkeleton";

const SignupPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [emailId, setEmailId] = useState("");
  const [password, setPassword] = useState("");
  const [processing, setProcessing] = useState(false);
  const [searchParam] = useSearchParams();

  useEffect(() => {
    checkUserVerified();
  }, []);

  const showLoginSuccessSnackbar = () => {
    dispatch(
      openNotification({
        message: "Successfully Logged In",
        severity: "success",
      })
    );
  };

  const passwordPolicy = `Your password should have:\n- Lowercase character required.\n- Uppercase character required.\n- Numeric character required.\n- Non-alphanumeric character required.\n- Minimum password length (ranges from 6 to 30 characters; defaults to 6).\n- Maximum password length (maximum length of 4096 characters)`;

  const checkUserVerified = () => {
    if (auth.currentUser) {
      navigate("/dashboard/keyword-selection", { replace: "true" });
    }
  };

  const handleGoogleSignin = async () => {
    const user = auth.currentUser;
    if (user) {
      handleUserAlreadyLoggedIn();
    } else {
      console.log("No signed in user found. So going for signin.");
      const provider = new GoogleAuthProvider();
      signInWithPopup(auth, provider)
        .then(async (result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          // const credential = GoogleAuthProvider.credentialFromResult(result);
          // const token = credential.accessToken;
          // The signed-in user info.
          const user = result.user;
          // TODO: Remove
          console.log("Signup success : " + JSON.stringify(result));
          try {
            const payload = {
              emailId: user.email,
              name: user.displayName,
              photoUrl: user.photoURL,
            };
            const saveData = await fetchBackendApiWrapper(
              "/api/v1/login",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
              },
              null
            );
            if (saveData.ok) {
              saveData.json().then((res) => {
                console.log("Signin Success. : " + res.emailId);
                navigate(`/dashboard/keyword-selection`, {
                  replace: "true",
                  state: { email: res.email },
                });
                showLoginSuccessSnackbar();
              });
            }
          } catch (err) {
            console.error("Error in saving user : " + JSON.stringify(err));
            await signOut(auth);
            throw err;
          }
        })
        .catch((error) => {
          let message = "Signup Failed. Please try again.";
          if (error !== null && error !== undefined && error.code) {
            if (
              error.code === "auth/account-exists-with-different-credential"
            ) {
              message =
                "Signup Failed. Account exists with different credentials";
            }
          }
          console.error("Signup error : " + JSON.stringify(error));
          dispatch(
            openNotification({
              message: message,
              severity: "error",
            })
          );
        });
    }

    function handleUserAlreadyLoggedIn() {
      console.log("Already Signed in user found. So going for Login.");
      if (auth.currentUser) {
        console.log("Login Success.");
        navigate("/dashboard/keyword-selection", { replace: "true" });
        showLoginSuccessSnackbar();
      }
    }
  };

  const handleEmailSignin = async () => {
    if (processing) return;
    setProcessing(true);
    await signOut(auth);
    createUserWithEmailAndPassword(auth, emailId, password)
      .then(async (credential) => {
        const user = credential.user;
        // console.log("Login success : " + JSON.stringify(credential));
        sendVerificationEmail();
        try {
          const payload = {
            emailId: user.email,
          };
          const saveData = await fetchBackendApiWrapper(
            "/api/v1/login",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(payload),
            },
            null
          );
          if (saveData.ok) {
            saveData.json().then((res) => {
              console.log("Signup Success. : " + res.emailId);

              navigate(`/dashboard/keyword-selection`, {
                replace: "true",
                state: { email: res.email },
              });
              showLoginSuccessSnackbar();
            });
          }
        } catch (err) {
          console.error("Error in saving user : " + JSON.stringify(err));
          await signOut(auth);
          throw err;
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        console.error("Signup error : " + JSON.stringify(error));
        let message = "Unable to Signup. Please try again.";
        if (errorCode === "auth/invalid-email") {
          message = "Please enter a valid email";
        }
        if (errorCode === "auth/email-already-in-use") {
          message = "Email already registered";
        }
        if (errorCode === "auth/weak-password") {
          message = "Please use a Strong password";
        }
        dispatch(
          openNotification({
            message: message,
            severity: "error",
          })
        );
      });
    setProcessing(false);
  };

  const sendVerificationEmail = () => {
    const actionCodeSettings = {
      url: `${window.location.origin}/verifyEmail?eId=${auth.currentUser.email}`,
      handleCodeInApp: true,
    };
    sendSignInLinkToEmail(auth, emailId, actionCodeSettings)
      .then(() => {
        localStorage.setItem("emailForSignIn", emailId);
        console.log("Verification email sent to : " + emailId);
      })
      .catch((error) => {
        console.error(
          "Error in sending verification email " + JSON.stringify(error)
        );
      });
  };

  return (
    <main className="bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-blue-100 to-80% to-white">
      {loading && (
        <div className="flex w-full justify-center mt-12 items-center">
          <LoadingPageSkeleton />
        </div>
      )}
      {!loading && (
        <div className="flex w-full relative flex-row justify-center items-center">
          <div className="flex flex-col justify-center items-center w-full relative gap-10">
            <div className="mt-8">
              <a href="https://thehyperbrand.com/">
                <img
                  src="/images/THB.svg"
                  alt="TheHyperbrand Logo"
                  width={140}
                  height={32}
                />
              </a>
            </div>
            <div className="flex relative flex-col justify-start items-center py-6 shadow-lg rounded-lg bg-white px-10 md:px-20">
              <h1 className="font-montserrat text-2xl text-gray-700 font-semibold">
                Sign Up
              </h1>
              <h2 className="mt-4 font-montserrat text-lg text-gray-400 font-light text-center">
                To create amazing content!
              </h2>
              <div className="mt-8"></div>
              <button
                onClick={handleGoogleSignin}
                className="flex gap-4 justify-center items-center py-3 px-2 md:px-8 rounded-lg border-2 border-slate-200 hover:border-slate-300 hover:shadow-xl"
              >
                <img
                  src="/images/google.webp"
                  height={24}
                  width={24}
                  alt="Sign in with google"
                />
                <div className="font-montserrat">Continue With Google</div>
              </button>
              <div className="my-8 relative flex justify-around items-center gap-4 w-full">
                <hr className="bg-gray-200 w-full" />
                <div className="text-slate-300">OR</div>
                <hr className="bg-gray-200 w-full" />
              </div>
              <div className="my-4 relative w-full">
                <input
                  type="text"
                  placeholder="Enter your email address"
                  className="font-montserrat bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                  value={emailId}
                  onChange={(event) => setEmailId(event.target.value)}
                />
              </div>
              <div className="my-2 relative w-full">
                <input
                  type="password"
                  placeholder="Enter Password"
                  className="font-montserrat bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                />
              </div>
              {password.length > 0 && (
                <div className="my-2 relative w-full bg-blue-100 border rounded-lg p-2.5 font-light text-xs font-montserrat whitespace-pre-wrap break-words">
                  {passwordPolicy}
                </div>
              )}
              <div className="my-4 relative w-full">
                <button
                  onClick={handleEmailSignin}
                  className="font-montserrat bg-thbBlue relative justify-center items-center py-2 flex w-full rounded-lg text-white hover:bg-blue-600"
                >
                  {processing ? (
                    <CircularProgress
                      size="1rem"
                      variant="indeterminate"
                      sx={{ color: "white" }}
                    />
                  ) : (
                    "Register"
                  )}
                </button>
              </div>
              <div className="flex relative w-full justify-center items-center gap-2">
                <div className="font-montserrat text-black">
                  Already have a account?
                </div>
                <Link to={"/login"}>
                  <div className="font-montserrat text-thbBlue font-semibold cursor-pointer">
                    Login
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

export default SignupPage;
